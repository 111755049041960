"use client"
import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion";
import Logo from '../images/logo-service-oficial-caba.png';
import { Icon } from '@iconify/react';
import Wsp from '../images/whatsapp-logo.webp';
import Phone from '../images/llamada-logo.webp';

const Header = () => {
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolling(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbarClass = scrolling ? 'bg-black text-white' : 'bg-black/50 text-white';

  const phoneNumber = "1156367024";

  const handlePhoneClick = () => {
    window.location.href = `tel:${phoneNumber}`;
  };

  return (
    <>
      <header className={`flex flex-row items-center w-full fixed h-20 z-50 transition-all duration-700 ease-in-out ${navbarClass}`}>
        <div className="w-full h-auto flex items-center">
          <motion.div
            className="lg:absolute lg:-left-20 xs:-left-24 xs:ml-4"
            initial={{ opacity: 0, x: -250 }}
            animate={{ opacity: 1, x: 100 }}
            transition={{ ease: "easeOut", duration: 1 }}
          >
            <img
              src={Logo}
              alt="Logo de Service Oficial CABA, reparacion de heladeras en Capital Federal"
              className=''
            />
          </motion.div>
          <div className='mx-auto xl:w-1/2 xl:pr-0 lg:w-1/2 lg:absolute lg:right-2 lg:pr-0 '>
            <motion.p
              className='my-auto uppercase text-xl 2xl:text-2xl xl:text-xl sm:text-sm xs:text-xs xs:pl-3'
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ ease: "easeOut", duration: 2 }}
            >
              <b className='text-white'>
                Horarios de Atención:
              </b> Lunes a Sábados de 9:00 a 20:00hs
            </motion.p>
          </div>
          <div className='2xl:hidden xl:hidden lg:hidden'>
            <motion.div
              className='flex flex-row items-center sm:hidden'
              initial={{ opacity: 0, x: 250 }}
              animate={{ opacity: 1, x: -120 }}
              transition={{ ease: "easeOut", duration: 1 }}
            >
            <motion.button id="link" onClick={handlePhoneClick} className='drop-shadow-[0_4.2px_1.2px_rgba(0,0,0,0.8)] flex flex-row items-center justify-center bg-gradient-to-l from-sky-400 to-sky-700 text-white font-bold
               text-3xl p-2 border  hover:shadow-naranja transition-all duration-700 ease-in-out lg:mt-12 sm:text-2xl sm:flex sm:justify-center xs:mt-16' initial={{ opacity: 0 }} animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}>
              <div className='flex flex-row items-center'>
                <Icon icon="line-md:phone-call-loop" className='mr-1 drop-shadow-[0_4.2px_1.2px_rgba(0,0,0,0.8)] sm:mr-0'/>
                <span className='drop-shadow-[0_4.2px_1.2px_rgba(0,0,0,0.8)]'>1156367024</span>
              </div>
            </motion.button>
            </motion.div>
          </div>
        </div>

        <div className='fixed right-0 bottom-0 z-10'>
          <a href="https://api.whatsapp.com/send?phone=541156367024" target={'_blank'} rel="noreferrer">
            <img src={Wsp} alt="Whatsapp logo" className='w-24 h-24 sm:w-20 sm:h-20 xs:w-16 xs:h-16' />
          </a>
        </div>

        <div className='fixed left-2 bottom-3 z-20 hidden sm:block' onClick={handlePhoneClick}>
          <img src={Phone} alt="Ícono telefónico" className='w-24 h-24 sm:w-16 sm:h-16 xs:w-12 xs:h-12' />
        </div>
      </header>
    </>
  )
}

export default Header;
