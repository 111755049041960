"use client"
import React from "react";
import useForm from "./useForm";
import { motion } from 'framer-motion'
import { BannerTwo } from "./BannerSm";
import { Icon } from '@iconify/react';


const ContactSection = () => {
  const initialData = {
    nombre: "",
    correo: "",
    asunto: "",
    mensaje: "",
    telefono: "",
  };

  const onValidate = (form) => {
    let errors = {};
    let regexName = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
    let regexEmail = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
    let regexComments = /^.{1,255}$/;
    let regexPhoneNumber = /^[\d-]+$/;

    if (!form.nombre.trim()) {
      errors.nombre = 'El campo "Nombre" no debe estar vacío.';
    } else if (!regexName.test(form.nombre)) {
      errors.nombre = 'El campo "Nombre" solo acepta letras y espacios.';
    }

    if (!form.correo.trim()) {
      errors.correo = 'El campo "Correo" no debe estar vacío.';
    } else if (!regexEmail.test(form.correo)) {
      errors.correo = 'El campo "Correo" contiene un formato no.';
    }

    if (!form.asunto.trim()) {
      errors.asunto = 'El campo "Asunto" no debe estar vacío.';
    } else if (!regexName.test(form.asunto)) {
      errors.asunto = 'El campo "Asunto" solo acepta letras y espacios.';
    }

    if (!form.telefono.trim()) {
      errors.telefono = 'El campo "Teléfono" es obligatorio.';
    } else if (!regexPhoneNumber.test(form.telefono)) {
      errors.telefono = 'El campo "Teléfono" solo debe contener números y guiones.';
    }

    if (!form.mensaje.trim()) {
      errors.mensaje = 'El campo "Mensaje" no debe estar vacío.';
    } else if (!regexComments.test(form.mensaje)) {
      errors.mensaje = 'El campo "Mensaje" acepta solo 255 caracteres.';
    }

    return errors;
  };

  const { form, errors, loading, handleChange, handleSubmit } = useForm(
    initialData,
    onValidate
  );

  const phoneNumber = "1156367024"; // Número de teléfono para realizar la llamada

  const handlePhoneClick = () => {
    window.location.href = `tel:${phoneNumber}`;
  };

  return (
    <>
      <BannerTwo />
      <div className="h-auto bg-contact bg-no-repeat bg-cover  w-full flex flex-row items-center justify-center flex-wrap sm:bg-center">
      

        <div className="flex items-center bg-black bg-opacity-75  justify-center flex-col w-1/2 
          2xl:w-[100%] lg:w-[100%]  sm:w-[100%]">
          <h4 className="pt-4 text-4xl font-bold text-center text-white sm:text-xl">ENVÍANOS TU CONSULTA</h4>
          <p className="pt-4 px-24 w-[70%] text-white flex flex-col items-center 2xl:text-2xl xl:text-center  sm:w-full sm:px-4 sm:text-base">
            CONTACTANOS POR CORREO ELECTRÓNICO O LLAMANDO AL:
          </p>
          <div className="flex flex-row items-center pb-4">
            <motion.button id="link" onClick={handlePhoneClick} className='drop-shadow-[0_4.2px_1.2px_rgba(0,0,0,0.8)] flex flex-row items-center justify-center bg-gradient-to-l from-sky-400 to-sky-700 text-white font-bold
               text-3xl p-2 border  hover:shadow-naranja transition-all duration-700 ease-in-out lg:mt-12 sm:text-2xl sm:flex sm:justify-center sm:mt-0' initial={{ opacity: 0 }} animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}>
              <div className='flex flex-row items-center'>
                <Icon icon="line-md:phone-call-loop" className='mr-1 drop-shadow-[0_4.2px_1.2px_rgba(0,0,0,0.8)] sm:mr-0'/>
                <span className='drop-shadow-[0_4.2px_1.2px_rgba(0,0,0,0.8)]'>1156367024</span>
              </div>
            </motion.button>
          </div>

          <form className="" onSubmit={handleSubmit}>
            <label className="text-white form-label font-bold text-xl  2xl:text-2xl sm:pl-2 sm:text-lg">Nombre</label>
            <input
              type="text"
              className="form-control rounded-xl sm:w-[95%] sm:mx-auto hover:bg-sky-200"
              name="nombre"
              value={form.nombre}
              onChange={handleChange}
              placeholder="Ingrese su nombre aquí"
            />
            {errors.nombre && (
              <div className="alert alert-danger p-2 rounded-xl mt-1 sm:w-[95%] sm:mx-auto">{errors.nombre}</div>
            )}

            <label className="text-white form-label pt-2 font-bold text-xl 2xl:text-2xl sm:pl-2 sm:text-lg">Correo electrónico</label>
            <input
              type="email"
              className="form-control rounded-xl sm:w-[95%] sm:mx-auto hover:bg-sky-200 "
              name="correo"
              value={form.correo}
              onChange={handleChange}
              placeholder="Ingrese su correo electrónico aquí"
            />
            {errors.correo && (
              <div className="alert alert-danger rounded-xl mt-1 p-2 sm:w-[95%] sm:mx-auto">{errors.correo}</div>
            )}

            <label className=" text-white form-label pt-2 font-bold text-xl  2xl:text-2xl sm:pl-2 sm:text-lg">Asunto</label>
            <input
              type="text"
              className="form-control rounded-xl sm:w-[95%]  sm:mx-auto hover:bg-sky-200"
              name="asunto"
              value={form.asunto}
              onChange={handleChange}
              placeholder="Ingrese su asunto aquí"
            />
            {errors.asunto && (
              <div className="alert alert-danger rounded-xl mt-1 p-2 sm:w-[95%] sm:mx-auto">{errors.asunto}</div>
            )}

            <label className="text-white form-label pt-2 font-bold text-xl  2xl:text-2xl sm:pl-2 sm:text-lg">Teléfono</label>
            <input
              type="text"
              className="form-control rounded-xl sm:w-[95%] sm:mx-auto hover:bg-sky-200"
              name="telefono"
              value={form.telefono}
              onChange={handleChange}
              placeholder="Ingrese su número de teléfono aquí"
            />
            {errors.telefono && (
              <div className="alert alert-danger p-2 mt-1 rounded-xl sm:w-[95%] sm:mx-auto">{errors.telefono}</div>
            )}

            <label className="text-white form-label pt-2 font-bold text-xl  2xl:text-2xl sm:pl-2 sm:text-lg">Mensaje</label>
            <textarea
              className="form-control rounded-xl sm:w-[95%] sm:mx-auto hover:bg-sky-200"
              name="mensaje"
              value={form.mensaje}
              onChange={handleChange}
              cols="40"
              rows="6"
              placeholder="Ingrese mensaje aquí"
            />
            {errors.mensaje && (
              <div className="alert alert-danger p-2 mt-1 rounded-xl sm:w-[95%] sm:mx-auto">{errors.mensaje}</div>
            )}

            <div className="flex items-center justify-center py-4">
              <motion.button
                className="text-white py-2 rounded-xl font-bold text-xl bg-gradient-to-b from-yellow-400 via-orange-400 to-orange-500 
              w-[100%] sm:w-[95%]"
                type="submit"
                disabled={loading}
              >
                {loading ? "Enviando..." : "Enviar"}
              </motion.button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ContactSection;