"use client"
import React from 'react'
import Icono1 from '../images/icono2.webp'
import Icono2 from '../images/icono1.webp'
import Icono3 from '../images/icono3.webp'
import { BannerThree } from './BannerSm'

const BrandsSection = () => {

  const arrayBanner = [
    {
      img: Icono1,
      alt: "Servicio tecnico de heladeras a domicilio en Capital Federal",
      descripcion: "Servicio técnico de heladeras en Capital Federal"
    },
    {
      img: Icono2,
      alt: "Atención telefónica",
      descripcion: "Atención telefónica inmediata"
    },
    {
      img: Icono3,
      alt: "Reparacion y Servicio Tecnico de todas las marcas de Heladera",
      descripcion: "Reparación de todas las marcas"
    },
  ]

  return (
    <>
      <BannerThree />
      <section className='flex items-center bg-transparent  xs:py-2'>
       
      </section>
      <div className='flex '>
        
        <div className='w-full flex items-center flex-col bg-fnd-image bg-no-repeat bg-cover bg-bottom'>
        <div className='w-full h-auto 2xl:w-full sm:w-[97%] '>
          <div className='flex flex-row justify-evenly w-auto py-20 sm:justify-center sm:flex-wrap  sm:pb-16'>
            {arrayBanner.map((imgs, index) => (
              <div key={index} className='max-w-sm flex flex-col items-center justify-center'>
                <img src={imgs.img} alt={imgs.alt} className='' />
                <p className='w-2/4 text-black text-2xl font-bold text-center 2xl:text-3lg lg:text-xl lg:w-1/2 sm:text-lg'>{imgs.descripcion}</p>
              </div>
            ))}
          </div>
        </div>
          <h4 className='text-center py-4 text-2xl font-bold'>¿Problemas con tu heladera? <span className='text-green-500'>Contáctanos</span></h4>
          <ul className='pb-8 text-2xl xs:text-lg xs:ml-6 sm:text-2xl'>
            <li className='text-xl list-disc pb-1 '>Compresor defectuoso</li>
            <li className='text-xl list-disc pb-1 '>Problemas eléctricos</li>
            <li className='text-xl list-disc pb-1 '>Termostato averiado</li>
            <li className='text-xl list-disc pb-1 '>Obstrucción en las bobinas del condensador</li>
            <li className='text-xl list-disc pb-1 '>Fallo en el ventilador del evaporador</li>
            <li className='text-xl list-disc pb-1 '>Sello de la puerta dañado</li>
            <li className='text-xl list-disc pb-1 '>Acumulación de hielo en el evaporador</li>
            <li className='text-xl list-disc pb-1 '>Filtro de agua obstruido</li>
            <li className='text-xl list-disc pb-1 '>Válvula de agua con fugas</li>
            <li className='text-xl list-disc '>Problemas con el sistema de descongelación</li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default BrandsSection



