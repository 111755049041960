import React from 'react'
import Ref from '../images/logo-service-oficial-caba.png'
import { motion } from 'framer-motion'
import { Icon } from '@iconify/react';


const FooterSection = () => {
  const phoneNumber = "1156367024"; // Número de teléfono para realizar la llamada

  const handlePhoneClick = () => {
    window.location.href = `tel:${phoneNumber}`;
  };
  return (
    <>
      <footer className='h-auto w-full flex flex-row justify-around items-center lg:flex-wrap  xs:justify-center ' >
        <div className='w-full flex flex-row justify-evenly items-center sm:grid sm:place-items-center'>
          <div className='sm:mt-2'>
            <img src={Ref} alt="Service Oficial-CABA reparacion de heladeras en Capital Federal" className='' />
          </div>
          <div className=''>
            <ul className='text-black 2xl:pl-8 2xl:pt-4 sm:py-4 xs:pl-0'>
              <li className=' pt-2 text-xl text-center text-sky-500 font-bold sm:text-2lg sm:text-lg'>Horarios de Atención:</li>
              <li className='list-disc '>Lunes a Viernes: 9:00 a 20:00hs</li>
              <li className='list-disc '>Sábados: 9:00 a 16:00hs.</li>
              <li className='list-disc  sm:lib-4'>Domingos y Feriados: Cerrado</li>
            </ul>
          </div>
          <div className='2xl:pl-4 xs:pl-0 sm:flex sm:justify-center sm:flex-col'>
            <span className='text-black text-xl text-center'>CONTACTO: </span>
            <motion.button id="link" onClick={handlePhoneClick} className='flex flex-row items-center justify-center bg-gradient-to-l from-sky-400 to-sky-700 text-white font-bold
               text-3xl p-2 border  hover:shadow-naranja transition-all duration-700 ease-in-out lg:mt-12 sm:text-2xl sm:flex sm:justify-center sm:mt-0 sm:mx-auto' initial={{ opacity: 0 }} animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}>
              <div className='flex flex-row items-center'>
                <Icon icon="line-md:phone-call-loop" className='mr-1 drop-shadow-[0_4.2px_1.2px_rgba(0,0,0,0.8)] sm:mr-0' />
                <span className='drop-shadow-[0_4.2px_1.2px_rgba(0,0,0,0.8)]'>1156367024</span>
              </div>
            </motion.button>
            <h6 className='font-bold text-lg text-center text-sky-500 2xl:pl-2 sm:text-md xs:pl-0 xs:text-sm xs:mb-2'>¡GRACIAS POR CONFIAR EN NOSOTROS!</h6>
          </div>
        </div>
      </footer>
      <div className='h-12 bg-gray-950 text-white flex items-center text-lg justify-center 2xl:text-2xl lg:text-center lg:text-xl sm:h-16 sm:text-sm sm:px-2 '>
        Todos los logos y marcas representadas en la página son propiedad únicamente de cada fabricante.
      </div>
      <div className='flex items-center pt-2 justify-center bg-gray-900'>
        <a href='#'>
          <Icon icon="icon-park:arrow-circle-up" height={40} className='py-1' />
        </a>
      </div>
      <div className='h-12 bg-gray-900  text-white decoration-none flex items-center justify-center flex-col sm:px-2 sm:text-xs sm:text-center 2xl:text-xl'>
        <a href="https://pbx-six.vercel.app/" target="_blank" className='font-bold decoration-transparent uppercase italic' rel="noreferrer">developed by Bakutech</a>
      </div>
    </>
  )
}

export default FooterSection