"use client"
import React from 'react'
import { Icon } from '@iconify/react';
import { motion } from 'framer-motion'

const Banner = () => {

  const phoneNumber = "1156367024";

  const handlePhoneClick = () => {
    window.location.href = `tel:${phoneNumber}`;
  };

  return (
    <>
      <section className='bg-main-banner bg-no-repeat bg-fixed bg-cover h-[73vh] flex justify-center xl:bg-center sm:bg-center xs:py-0 xs:bg-center '>
        <div className='flex flex-col items-center justify-center'>
          <motion.h1
          initial={{ opacity: 0 }} animate={{ opacity: 1 }}
          exit={{ opacity: 0 }} transition={{ ease: "easeOut", duration: 2 }}
           className='flex flex-col items-center text-5xl text-white font-bold drop-shadow-[0_4.2px_1.2px_rgba(0,0,0,0.8)] sm:text-3xl'>
            <span>SERVICE OFICIAL CABA</span>
            <span className='text-2xl font-light mb-2 sm:text-center sm:text-lg xs:w-[90%]'>REPARACIÓN Y SERVICIO TÉCNICO DE HELADERAS EN CAPITAL FEDERAL | MANTENIMIENTO
            </span>
          </motion.h1>
          <motion.p className=' text-3xl text-white font-medium drop-shadow-[0_4.2px_1.2px_rgba(0,0,0,0.8)] text-center 2xl:hidden xl:hidden sm:text-center sm:text-xl xs:hidden ' initial={{ opacity: 0 }} animate={{ opacity: 1 }}
            exit={{ opacity: 0 }} transition={{ ease: "easeOut", duration: 2 }}>
            CONTÁCTENOS
          </motion.p>
          <div className='' >
            <motion.button id="link" onClick={handlePhoneClick} className='drop-shadow-[0_4.2px_1.2px_rgba(0,0,0,0.8)] flex flex-row items-center justify-center bg-gradient-to-l from-sky-400 to-sky-700 text-white font-bold
               text-3xl p-2 border hover:shadow-naranja transition-all duration-700 ease-in-out lg:mt-12 sm:text-2xl sm:flex sm:justify-center xs:mt-16' initial={{ opacity: 0 }} animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}>
              <div className='flex flex-row items-center'>
                <Icon icon="line-md:phone-call-loop" className='mr-1 drop-shadow-[0_4.2px_1.2px_rgba(0,0,0,0.8)] sm:mr-0'/>
                <span className='drop-shadow-[0_4.2px_1.2px_rgba(0,0,0,0.8)]'>1156367024</span>
              </div>
            </motion.button>
          </div>
          <div className='text-white'>
            <p className='hidden sm:block'>Tocá para comunicarte con un representante</p>
          </div>
        </div>
      </section>
    </>
  )
}

export default Banner